<template>
  <div>
    <v-card :disabled="isProcessing" :loading="isProcessing">
      <template v-slot:progress>
        <v-progress-linear
          absolute
          color="success"
          height="4"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-tabs v-model="tab" class="deep-gl" left dark icons-and-text>
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          <v-toolbar-title>
            {{ name }}
          </v-toolbar-title>
        </v-tab>
        <v-spacer></v-spacer>

        <div class="buttons-tab"></div>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <template v-slot:progress>
              <v-progress-linear
                absolute
                color="success"
                height="4"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-card-text>
              <v-form @submit.prevent ref="requestData">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Name"
                      v-model="requestData.name"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Email"
                      v-model="requestData.email"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Phone"
                      v-model="requestData.phone"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Company"
                      v-model="requestData.company_name"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="External Id"
                      v-model="requestData.external_id"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" v-on:click="back(true)">Back</v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { GET_COMPANY_CONTACT } from "@/store/companyContact.module";
import { SHOW_SNACK } from "@/store/snack.module";
import TPConfirm from "@/views/partials/dialogs/Confirm.vue";

export default {
  name: "ViewContact",
  components: {
    TPConfirm,
  },
  data() {
    return {
      name: "View Contact",
      tab: "tab-1",
      isProcessing: false,
      requestData: {},
    };
  },
  mounted() {
    this.selectCompanyContact();
  },
  methods: {
    back() {
      this.$router.push({ name: "company-contacts" });
    },
    selectCompanyContact() {
      this.isProcessing = true;
      this.$store
        .dispatch(GET_COMPANY_CONTACT, this.$route.params.id)
        .then((response) => {
          this.requestData = response;
        })
        .catch((error) => {
          this.$store.dispatch(SHOW_SNACK, {
            message: this.$t("GENERAL.UNEXPECTED_ERROR"),
            type: "error",
          });
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
  },
};
</script>